var render = function render(){
  var _vm$brand, _vm$brand2, _vm$brand3, _vm$brand4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('ExternalServices')))]), _c('v-col', [_c('v-card', {
    staticClass: "subtitle-1 d-inline-flex align-center justify-space-between px-5 py-3 col-12 col-md-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-center title"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-invoice ")]), _c('span', [_vm._v("Quickbooks")])], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "ml-4 mr-2"
  }, [_vm._v(_vm._s(_vm.$t('Id:')))]), _c('span', {
    staticClass: "px-3 grey lighten-4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_vm._v(_vm._s((_vm$brand = _vm.brand) === null || _vm$brand === void 0 ? void 0 : _vm$brand.quickbooksId))]), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "href": `${_vm.$appConfig.qboURL}/customerdetail?nameId=${(_vm$brand2 = _vm.brand) === null || _vm$brand2 === void 0 ? void 0 : _vm$brand2.quickbooksId}`,
      "target": "_blank",
      "color": "orange",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "ma-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")])], 1)], 1)])], 1), _c('v-col', [_c('v-card', {
    staticClass: "subtitle-1 d-inline-flex align-center justify-space-between px-5 py-3 col-12 col-md-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-center title"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fab fa-stripe-s ")]), _c('span', [_vm._v("Stripe")])], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "ml-4 mr-2"
  }, [_vm._v(_vm._s(_vm.$t('Id:')))]), _c('span', {
    staticClass: "px-3 grey lighten-4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_vm._v(_vm._s((_vm$brand3 = _vm.brand) === null || _vm$brand3 === void 0 ? void 0 : _vm$brand3.stripeId))]), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "href": `https://dashboard.stripe.com/customers/${(_vm$brand4 = _vm.brand) === null || _vm$brand4 === void 0 ? void 0 : _vm$brand4.stripeId}`,
      "target": "_blank",
      "color": "orange",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "ma-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-external-link-alt ")])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }