<template>
  <v-card
    flat
    class="px-3 mx-sm-5"
  >
    <v-card-title>{{ $t('ExternalServices') }}</v-card-title>
    <v-col>
      <v-card
        outlined
        class="subtitle-1 d-inline-flex align-center justify-space-between px-5 py-3 col-12 col-md-6"
      >
        <div class="d-flex align-center title">
          <v-icon small class="mr-2">
            fas fa-file-invoice
          </v-icon>
          <span>Quickbooks</span>
        </div>
        <div class="d-flex align-center">
          <span class="ml-4 mr-2">{{ $t('Id:') }}</span>
          <span class="px-3 grey lighten-4" style="border-radius: 4px">{{
            brand?.quickbooksId
          }}</span>
          <v-btn
            :href="`${$appConfig.qboURL}/customerdetail?nameId=${brand?.quickbooksId}`"
            target="_blank"
            color="orange"
            icon
            class="text-none ml-2"
          >
            <v-icon small class="ma-2">
              fas fa-external-link-alt
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-col>
      <v-card
        outlined
        class="subtitle-1 d-inline-flex align-center justify-space-between px-5 py-3 col-12 col-md-6"
      >
        <div class="d-flex align-center title">
          <v-icon small class="mr-2">
            fab fa-stripe-s
          </v-icon>
          <span>Stripe</span>
        </div>
        <div class="d-flex align-center">
          <span class="ml-4 mr-2">{{ $t('Id:') }}</span>
          <span class="px-3 grey lighten-4" style="border-radius: 4px">{{
            brand?.stripeId
          }}</span>
          <v-btn
            :href="`https://dashboard.stripe.com/customers/${brand?.stripeId}`"
            target="_blank"
            color="orange"
            icon
            class="text-none ml-2"
          >
            <v-icon small class="ma-2">
              fas fa-external-link-alt
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-card>
</template>

<script>

const i18nData = require('./pageBrandExternalServices.i18n.json');

export default {
  name: 'PageBrandExternalServices',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      servicesId: null,
      externalServicesErrorMessage: '',
    };
  },
  created() {
    /**
     * GET BRAND EXTERNAL SERVICES ID
     */
    this.getAdminBrand();
  },
  methods: {
    /**
     * GET BRAND EXTERNAL SERVICES ID
     */
    getAdminBrand() {
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brand) => {
            this.brand = brand;
          }
        );
    },
  },
};
</script>
